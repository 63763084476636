import React from 'react'
import { Link } from 'gatsby'
import Header from '../components/header-dark'
import Footer from '../components/footer'

const SentMessage = () => 
  <div>
    <Header />
    <section className="sent-message">
      <h2>Thank You</h2>
      <p>
        内容が送信されました。<br />
        担当者が確認するまでしばらくお待ちください。</p>
      <Link to="/" className="button">トップページへ</Link>
    </section>
    <Footer />
  </div>

export default SentMessage
