import React, { useState } from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo-black.svg'

const Header = () => {
  const [show, setShow] = useState(false)
  return (
    <header className="global-header dark">
      <Link to="/"><img src={logo} alt="Smart Home Inc." /></Link>
      <ul className={show ? 'show' : 'hide'}>
        <li>
          <Link to="/company">企業情報</Link>
        </li>
        <li>
          <Link to="/message">代表メッセージ</Link>
        </li>
        <li>
          <Link to="/service">サービス</Link>
        </li>
        {/* <li>
          <Link to="/work">施工実績</Link>
        </li> */}
        <li>
          <Link to="/for-agencies">代理店募集</Link>
        </li>
        <li>
          <Link to="/recruit">採用情報</Link>
        </li>
        <li>
          <Link to="/contact">お問い合わせ</Link>
        </li>
      </ul>
      <button className={`${show ? 'show' : 'hide'} menu-close`} onClick={() => setShow(false)}>✕</button>
      <button className="humberger" onClick={() => setShow(true)}></button>
    </header>
  )
}
  

export default Header
