import React from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo-black.svg'
import partner from '../images/partner.svg'

const Footer = () =>
  <footer className="global-footer">
    <section>
      <div>
        <img src={logo} alt="Smart Home Inc." />
        <address>〒530-0012<br />
          大阪府大阪市北区芝田2丁目8-11<br />
          共栄ビル3階
        </address>
      </div>
      <img src={partner} alt="私たちはヴィッセル神戸のサポートファミリーです"/>
      <Link to="/contact" className="button default contact-button">Contact</Link>
    </section>
    <small>© 株式会社スマートホーム</small>
  </footer>

export default Footer